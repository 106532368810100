import React from 'react';
import ReactDOM from 'react-dom';
import withAnalytics, { initAnalytics } from 'react-with-analytics';
import { BrowserRouter, withRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import store from './store';
import ErrorBoundary from './components/ErrorBoundary';
import registerServiceWorker from './registerServiceWorker';
import { Provider } from 'react-redux';
// Turns on GA - we can wrap in a constant we pass in later
initAnalytics('UA-11039107-1');

const GoogleAnalyticsApp = withRouter(withAnalytics(App));

// Wraps the entire APP in GA to track routes
const AppWithGA = () => (
  <Provider store={store}>
  <BrowserRouter>
    <ErrorBoundary>
      <GoogleAnalyticsApp />
    </ErrorBoundary>
  </BrowserRouter>
  </Provider>
);

ReactDOM.render(<AppWithGA />, document.getElementById('root'));
registerServiceWorker();
