import styled from 'styled-components';
import ReactSVG from 'react-svg';
import Flex from '../../../ErrorBoundary/Shared/Flex'

const StyledSubpageHeader = styled.header`
  height: 56px;
  padding: 0 16px;
  background-color: ${props => props.theme.palette.white};

  @media ${props => props.theme.breakpoints.md} {
    padding: 0 32px;
    height: 68px;
  }

  @media ${props => props.theme.breakpoints.lg} {
    padding: 0 48px;
  }

  @media ${props => props.theme.breakpoints.xl} {
    padding: 0 16px;
  }
`;

const FlexContainer = styled(Flex)`
  max-width: 1254px;
  margin: 0 auto;
  height: 100%;
`;

const Logo = styled(ReactSVG)`
  & svg {
    height: 24px;
  }

  @media ${props => props.theme.breakpoints.md} {
    & svg {
      height: 40px;
    }

    & svg.company-com {
      height: 30px;
    }
  }
`;

StyledSubpageHeader.FlexContainer = FlexContainer;
StyledSubpageHeader.Logo = Logo;

export default StyledSubpageHeader;
