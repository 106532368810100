import styled from 'styled-components';

const StyledSubpage = styled.div``;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const Content = styled.div`
  margin: -60px 0px 100px;
  border-radius: 4px;
  box-shadow: 0 3px 8px 0 rgba(106, 126, 150, 0.14);
  background-color: ${props => props.theme.palette.white};
`;

StyledSubpage.Container = Container;
StyledSubpage.Content = Content;

export default StyledSubpage;
