// Absolute
import React from 'react';

// Relative
import StyledCountryFilter from '../styledCountryFilter';

const CountryFilter = () => {
  return (
    <StyledCountryFilter>
      <div className="container">
        <img
          className="filter-logo"
          src="https://bit.ly/2zr8FEn"
          alt="Site Not Available In Your Region"
        />
        <StyledCountryFilter.Title h2 center>
          We’ll let you know when we’re open in your area!
        </StyledCountryFilter.Title>
        <h3 className="filter-subheader">
          Company.com is currently only serving customers located in the United States and Canada.
          <span>We apologize for the inconvenience.</span>
        </h3>
      </div>
    </StyledCountryFilter>
  );
};

export default CountryFilter;
