export default function reducer(
  state = {
    authDetail: {},
    fetching: false,
    fetched: false,
    updating: false,
    updated: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case 'AUTH_CHECK':
      return { ...state, fetching: true };

    case 'AUTH_CHECK_FUFILLED':
      return { ...state, fetching: false, authDetail: action.payload };

    case 'AUTH_CHECK_REJECTED':
      return {
        ...state,
        fetching: false,
        error: true,
      };
    default:
      return { ...state };
  }
}
