export default function reducer(state = {
  fetching: false,
  fetched: false,
  error: null,
  coBrandedCss: {
    theme: {
      TextField: {
        shrink: false,
        inputLabel: {},
      },
    },
  },
}, action) {
  switch (action.type) {
    case 'FETCH_COBRANDED_CSS_DETAIL':
      return {
        ...state,
        fetching: true,
      };

    case 'FETCH_COBRANDED_CSS_DETAIL_REJECTED':
      return {
        ...state,
        fetching: false,
        error: action.payload,
      };

    case 'FETCH_COBRANDED_CSS_DETAIL_FUFILLED':
      return {
        ...state,
        fetching: false,
        updated: true,
        coBrandedCss: action.payload,
      };
    default:
      return { ...state };
  }
}
