const {
  REACT_APP_S3_BASE_URL
} = process.env;

export default {
  FOOTER_LOGO : `${REACT_APP_S3_BASE_URL}/images/footer-logo.png`,
  FOOTER_LOGO_2x :`${REACT_APP_S3_BASE_URL}/images/footer-logo@2x.png`,
  FOOTER_LOGO_3x :`${REACT_APP_S3_BASE_URL}/images/footer-logo@3x.png`,
  CHECKMARK : `${REACT_APP_S3_BASE_URL}/images/Checkmark.png`,
  SUBSCRIPTION_PRODUCT_BASE_URL : `${REACT_APP_S3_BASE_URL}/subscriptionImages/`,
  ERROR : `${REACT_APP_S3_BASE_URL}/dashboard_nextgen/company/images/error.svg`,
  COMPANY_LOGO : `${REACT_APP_S3_BASE_URL}/images/company-logo.png`,
  SQUARE : `url(${REACT_APP_S3_BASE_URL}/images/squares.png)`
}