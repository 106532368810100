import React from 'react';
import StyledGeneralError from './styledGeneralError';
import { Error404, Error401, Error421 } from './ErrorTypes';
import IMAGES from '../../images';

const GeneralError = ({ statusCode = undefined, history, auth }) => {
  return (
    <StyledGeneralError>
      <img className="error__icon" alt="error icon" src={IMAGES.ERROR} />
      <div className="error__icon__shadow" />
      <StyledGeneralError.Title h2 center>
        Oops! That’s not right…
      </StyledGeneralError.Title>
      {{
        '404': <Error404 history={history} />,
        '401': <Error401 history={history} />,
        '421': <Error421 auth={auth} />,
      }[statusCode] || (
        <p className="error__text">Something went wrong, please try again.</p>
      )}
    </StyledGeneralError>
  );
};

export default GeneralError;
