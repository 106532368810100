export default function reducer(state = {
  userEmailPassword: {},
  updating: false,
  updated: false,
  error: null,
}, action) {
  switch (action.type) {
    case 'UPDATE_EMAIL_PASSWORD':
      return {
        ...state,
        updating: true,
      };

    case 'UPDATE_EMAIL_PASSWORD_FUFILLED':
      return {
        ...state,
        updated: true,
        error: false,
        userEmailPassword: {
          password: ''
        },
      };

    case 'UPDATE_EMAIL_PASSWORD_REJECTED':
      return {
        ...state,
        updated: false,
        error: action.payload,
        userEmailPassword: {
          password: ''
        },
      };

    default:
      return { ...state };
  }
}
