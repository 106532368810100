export default function reducer(
  state = {
    businessProfile: {},
    fetching: false,
    fetched: false,
    updating: false,
    updated: false,
    error: null,
    accountUsers: { users: [], account: {} },
  },
  action
) {
  switch (action.type) {
    case 'FETCH_BUSINESS_PROFILE':
      return { ...state, fetching: true };

    case 'FETCH_BUSINESS_PROFILE_REJECTED':
      return { ...state, fetching: false, error: action.payload };

    case 'FETCH_BUSINESS_PROFILE_FUFILLED':
      return {
        ...state,
        fetching: false,
        fetched: true,
        businessProfile: action.payload,
      };

    case 'FETCH_ACCOUNT_USERS':
      return { ...state, fetching: true };

    case 'FETCH_ACCOUNT_USERS_REJECTED':
      return { ...state, fetching: false, error: action.payload };

    case 'FETCH_ACCOUNT_USERS_FUFILLED':
      const accountUsers = Object.assign({}, state.accountUsers);
      accountUsers.account = action.payload;
      return {
        ...state,
        fetching: false,
        fetched: false,
        accountUsers,
      };

    case 'UPDATE_BUSINESS_PROFILE':
      return {
        ...state,
        updating: true,
        updated: false,
      };

    case 'UPDATE_BUSINESS_PROFILE_FUFILLED': {
      return {
        ...state,
        updating: false,
        updated: true,
        businessProfile: action.payload,
      };
    }

    case 'UPDATE_BUSINESS_PROFILE_REJECTED': {
      return {
        ...state,
        updating: false,
        updated: false,
        error: action.payload,
      };
    }
    case 'UPDATE_ACCOUNT_USERS_FULLFILLED': {
      const accountUsers = Object.assign({}, state.accountUsers);
      accountUsers.users = action.payload;
      return {
        ...state,
        error: action.payload,
        fetched: true,
        updating: false,
        accountUsers,
      };
    }

    default:
      return { ...state };
  }
}
