import React from 'react';
import StyledSubpage from './styledSubpage';

const Subpage = props => (
  <StyledSubpage>
    <StyledSubpage.Container>
      <StyledSubpage.Content>{props.children}</StyledSubpage.Content>
    </StyledSubpage.Container>
  </StyledSubpage>
);

export default Subpage;
