import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { spinnerStyle, baseTheme } from '../../Theme/globalStyles';

class SecuredRoute extends Component {
  state = {
    isAuthenticated: false
  };

  componentWillMount() {
    this.handleAuth();
  }

  handleLoggedOut(rest) {
    // rest.location.state = { from: fromLocation };
    rest.history.push('/');
  }

  handleAuth = () => {
    const { auth, history, ...rest } = this.props;
    auth
      .isAuthenticated()
      .then(result => {
        if (result) {
          auth.pollAuthenticated(rest.location.pathname, result => {
            if (!result) {
              this.handleLoggedOut(rest);
            }
          });
          this.setState({ ...this.state, isAuthenticated: true });
        }
      })
      .catch(err => {
        history.push({
          pathname: '/callbacksilent',
          state: { from: rest.location || '' }
        });
      });
  };

  render() {
    const { component: Component, auth, ...rest } = this.props;
    const circularStyle = {
      color: baseTheme.primary
    };

    return this.state.isAuthenticated ? (
      <div className="container">
        <Component auth={auth} {...rest} />
      </div>
    ) : (
      <div style={spinnerStyle}>
        {' '}
        <CircularProgress size={80} thickness={5} style={circularStyle} />
      </div>
    );
  }
}

export default withRouter(SecuredRoute);
