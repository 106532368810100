import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import user from './userReducer';
import userBusiness from './userBusinessReducer';
import userPassword from './userPasswordReducer';
import userEmailPassword from './emailPasswordReducer';
import userPaymentDetail from './paymentReducers';
import formDetails from './payementFormReducer';
import weeblyDetails from './WeeblyReducer';
import userDetailAuth from './authReducer';
import subscriptionDetails from './subscriptionUpgradeReducer';
import childUser from './childUserReducer';
import cssChannelPartner from './cssReducer';
import provision from './provisionReducer';
import graphQl from './graphQlReducer';
import marketo from './marketoReducer';

export default combineReducers({
  user,
  userBusiness,
  userPassword,
  userEmailPassword,
  userPaymentDetail,
  formDetails,
  weeblyDetails,
  userDetailAuth,
  subscriptionDetails,
  childUser,
  cssChannelPartner,
  provision,
  graphQl,
  marketo,
  form: formReducer,
});
