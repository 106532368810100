import React from 'react';
import StyledSubpageHeader from './styledSubpageHeader';

const SubpageHeader = ({ theme }) => (
  <StyledSubpageHeader>
    <StyledSubpageHeader.FlexContainer alignCenter>
      <StyledSubpageHeader.Logo src={theme.logo} />
    </StyledSubpageHeader.FlexContainer>
  </StyledSubpageHeader>
);

export default SubpageHeader;
