export default function reducer(state = {
  userPassword: {},
  updating: false,
  updated: false,
  error: null,
}, action) {
  switch (action.type) {
    case 'UPDATE_USER_PASSWORD':
      return {
        ...state,
        updating: true,
      };

    case 'UPDATE_USER_PASSWORD_FUFILLED':
      return {
        ...state,
        updated: true,
        error: false,
        userPassword: {
          password: '',
          newpassword: '',
        },
      };

    case 'UPDATE_USER_PASSWORD_REJECTED':
      return {
        ...state,
        updated: false,
        error: action.payload,
        userPassword: {
          password: '',
          newpassword: '',
        },
      };

    default:
      return { ...state };
  }
}
