export default function reducer(
  state = {
    profile: [],
    products: null,
    fetching: false,
    fetched: false,
    updating: false,
    updated: false,
    error: null,
    userPackage: {},
    emailActive: false,
    loggedInUser:[],
    currentEditedUser:undefined
  },
  action
) {
  switch (action.type) {
    case 'FETCH_USER':
      return { ...state, fetching: true };

    case 'FETCH_USER_REJECTED':
      return { ...state, fetching: false, error: action.payload };

    case 'FETCH_USER_FUFILLED':
      return {
        ...state,
        fetching: false,
        fetched: true,
        profile: action.payload,
        loggedInUser:action.payload
      };
    //  IMUTABLE update of user
    case 'UPDATE_USER':
      return {
        ...state,
        updating: true,
        updated: false,
      };

    case 'UPDATE_USER_FUFILLED': {
      const { userProfile } = action.payload; // eslint-disable-line no-unused-vars
      return {
        ...state,
        updating: false,
        updated: true,
        profile: action.payload,
      };
    }
    case 'UPDATE_USER_CHILD_FULFILLED': {
      // const { userProfile } = action.payload; // eslint-disable-line no-unused-vars
      return {
        ...state,
        profile: action.payload,
        updating: false,
        updated: true,
      };
    }

    case 'UPDATE_USER_REJECTED': {
      return {
        ...state,
        updating: false,
        updated: false,
        error: action.payload,
      };
    }

    case 'UPDATE_USER_ACCOUNT_DETAILS':
      return {
        ...state,
        userPackage: action.payload.userPackage,
        emailActive: action.payload.emailActive,
      };

    case 'FETCH_ALL_PRODUCT':
      return {
        ...state,
        fetching: true,
        fetched: false,
      };
    case 'FETCH_ALL_PRODUCT_FUFILLED':
      return {
        ...state,
        fetching: false,
        fetched: true,
        products: action.payload,
      };
    case 'FETCH_ALL_PRODUCT_REJECTED':
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: action.payload,
      };
      case 'EDITED_USER':
      return {
        ...state,
        currentEditedUser: action.payload,
      };
    default:
      return { ...state };
  }
}
