export default function(env) {
  /* eslint-disable */
  (function() {
    var s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    if (env && env === 'prod') {
      s.src= 'https://static.zuora.com/Resources/libs/hosted/1.1.0/zuora-min.js';
    } else {
      s.src = 'https://apisandboxstatic.zuora.com/Resources/libs/hosted/1.1.0/zuora-min.js';
    }
    
    
    document.getElementsByTagName('head')[0].appendChild(s);
    })();
    /* eslint-enable */
}
