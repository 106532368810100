export default function reducer(state = {
  subscriptionDetails: {},
  fetching: false,
  fetched: false,
  updating: false,
  updated: false,
  error: null,
  subscriptionDetailsFormated: {
    params: {},
    subscriptions: [],
    mainHeader: '',
    headerText: '',
    bodyText: '',
  },
}, action) {
  switch (action.type) {
    case 'SUBSCIPTION_UPGRADE_DETAILS':
      return { ...state, fetching: true };

    case 'SUBSCIPTION_UPGRADE_DETAILS_REJECTED':
      return { ...state, fetching: false, error: action.payload };

    case 'SUBSCIPTION_UPGRADE_DETAILS_FUFILLED':
      return {
        ...state,
        fetching: false,
        fetched: true,
        subscriptionDetails: action.payload,
      };
    case 'SUBSCIPTION_UPGRADE_DETAILS_FORMATED_FUFILLED':
      return {
        ...state,
        fetching: false,
        fetched: true,
        subscriptionDetailsFormated: action.payload,
      };
    case 'UPDATE_USER_EMAIL_SUBSCRIPTION':
      return {
        ...state, fetching: true,
      };
    case 'UPDATE_USER_EMAIL_SUBSCRIPTION_FULFILED':
      return {
        ...state, fetching: false, fetched: true, error: false,
      };
    case 'UPDATE_USER_EMAIL_SUBSCRIPTION_REJECTED':
      return {
        ...state, fetching: false, fetched: true, error: action.payload,
      };
    default:
      return { ...state };
  }
}
