export default function reducer(state = {
  userPaymentDetail: {},
  fetching: false,
  fetched: false,
  updating: false,
  updated: false,
  error: null,
}, action) {
  switch (action.type) {
    case 'FETCH_PAYMENT_DETAILS':
      return { ...state, fetching: true };

    case 'FETCH_PAYMENT_DETAILS_REJECTED':
      return { ...state, fetching: false, error: action.payload };

    case 'FETCH_PAYMENT_DETAILS_FUFILLED':
      return {
        ...state,
        fetching: false,
        fetched: true,
        userPaymentDetail: action.payload,
      };
    case 'UPDATE_PAYMENT_DETAILS':
      return { ...state, updating: true };

    case 'UPDATE_PAYMENT_DETAILS_FUFILLED':
      return {
        ...state, updated: true, error: false, userPaymentDetail: action.payload,
      };

    case 'UPDATE_PAYMENT_DETAILS_REJECTED':
      return { ...state, updated: false, error: action.payload };

    case 'CREATE_PAYMENT_DETAILS':
      return { ...state, updating: true };

    case 'CREATE_PAYMENT_DETAILS_FUFILLED':
      return {
        ...state,
        updated: true,
        error: false,
        updating: false,
        userPaymentDetail: action.payload,
      };

    case 'CREATE_PAYMENT_DETAILS_REJECTED':
      return {
        ...state,
        updated: false,
        updating: false,
        error: action.payload,
      };
    case 'UPDATE_PAYMENT_SUBSCRIPTION_DETAILS':
      return { ...state, updating: true };
    case 'UPDATE_PAYMENT_SUBSCRIPTION_FUFILLED':
      return {
        ...state,
        updated: true,
        error: false,
        updating: false,
        userPaymentDetail: action.payload,
      };
    case 'UPDATE_PAYMENT_SUBSCRIPTION_REJECTED':
      return {
        ...state,
        updated: false,
        updating: false,
        error: action.payload,
      };
    default:
      return { ...state };
  }
}
