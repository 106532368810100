export default function reducer(
  state = {
    provision: { products: [] },
    fetching: false,
    fetched: false,
    updating: false,
    updated: false,
    validating: false,
    validated: false,
    error: null,
    alaCartProduct: [],
  },
  action
) {
  switch (action.type) {
    case 'PROVISION_FETCH_USER_PRODUCTS':
      return {
        ...state,
        updating: true,
      };
    case 'PROVISION_FETCH_USER_PRODUCTS_FUFILLED':
      return {
        ...state,
        updating: false,
        updated: true,
        provision: action.payload.provision,
        alaCartProduct: action.payload.alaCarteList,
      };
    case 'PROVISION_FETCH_USER_PRODUCTS_REJECTED':
      return {
        ...state,
        updating: false,
        error: action.payload,
      };
    case 'PROVISION_UPDATE_USER_PRODUCTS':
      return {
        ...state,
        updating: true,
      };
    case 'PROVISION_UPDATE_USER_PRODUCTS_FUFILLED':
      return {
        ...state,
        updating: false,
        updated: true,
      };
    case 'PROVISION_UPDATE_USER_PRODUCTS_REJECTED': {
      return {
        ...state,
        updating: false,
        error: action.payload,
      };
    }
    default:
      return { ...state };
  }
}
