export default function reducer(
  state = {
    sourceTileState: {},
    userTileState: {},
    fetching: false,
    fetched: false,
    updating: false,
    updated: false,
    error: null
  },
  action
) {
  switch (action.type) {
    case 'GRAPH_USER_UPDATE_TILE_STATE':
      return {
        ...state,
        updating: true,
        error: false,
        userTileState: action.payload
      };
    case 'GRAPH_SOURCE_TILE_STATE':
      return {
        ...state,
        fetching: true,
        error: false
      };
    case 'GRAPH_USER_UPDATE_TILE_STATE_REJECTED':
      return {
        ...state,
        updating: false,
        error: action.payload
      };
    case 'GRAPH_SOURCE_TILE_STATE_REJECTED':
      return {
        ...state,
        fetching: false,
        error: action.payload
      };
    case 'GRAPH_USER_UPDATE_TILE_STATE_FUFILLED':
      return {
        ...state,
        updating: false,
        updated: true
      };
    case 'GRAPH_SOURCE_TILE_STATE_FUFILLED':
      return {
        ...state,
        fetching: false,
        fetched: true,
        sourceTileState: action.payload
      };
    default:
      return {
        ...state
      };
  }
}
