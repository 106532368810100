import IMAGES from '../images'

export const breakpoints = {
  desktop: 'min-width: 1280px',
};
const anchorColorCompany = '#2ea5f2';
const styleFontFamily = 'Heebo, Helvetica, Arial, sans-serif';
export const baseTheme = (() => {
  switch (window.location.host) {
    case 'officedepot.account.company.local':
    case 'officedepot.account.company-corp.com':
    case 'officedepot.account.company.com':
    case 'officedepot.account.company.local:3001':
      return {
        primary: '#09afbb',
      };
    default:
      return {
        primary: '#2ea5f2',
        banner__image:
          'https://assets.company.com/dashboard_nextgen/company/images/company-banner.svg',
        logo: 'https://assets.company.com/dashboard_nextgen/company/images/logo.svg',
        palette: {
          white: '#FFFFFF',
          text__color: '#4C6372',
          brand__color: '#24BE51',
          primary__color: '#2EA5F2',
          secondary__color: '#48BFFF',
          tertiary__color: '#158CD9',
          badge__color: '#ED4F75',
          primary__gray: 'rgba(0,0,0,0.26)',
          secondary__gray: 'rgba(112,130,142,0.5)',
          template__gradient: 'linear-gradient(143deg, #26b84c, #24c674)',
        },
        breakpoints: {
          xs: `(min-width: 0px)`,
          sm: `(min-width: 320px)`,
          md: `(min-width: 600px)`,
          lg: `(min-width: 960px)`,
          xl: `(min-width: 1180px)`,
        },
      };
  }
})();

export const baseTheme1 = {
  resetBody: {
    padding: 0,
    margin: 0,
    fontFamily: styleFontFamily,
    textRendering: 'optimizeLegibility',
    '-webkit-font-smoothing': 'antialiased',
    '-moz-osx-font-smoothing': 'grayscale',
    a: {
      textDecoration: 'none',
      color: anchorColorCompany,
      fontSize: '16px',
      cursor: 'pointer',
    },
  },
  resetOptimize: {
    boxSizing: 'border-box',
  },
  resetHtml: 'height: 100%;',
  primary: anchorColorCompany,
  fontFamily: styleFontFamily,
  CompanyHeaderContainerCompanyLogoContainer: {
    backgroundImage: IMAGES.COMPANY_LOGO,
    marginRight: '10px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left',
  },
  CompanyHeaderContainerCompanyLabelContainer: {
    background:
      `#2ea5f3 ${IMAGES.SQUARE} 50% no-repeat`,
  },
  CompanyH1: {
    span: {
      fontWeight: '300',
      lineHeight: 2,
      color: '#4c6372',
    },
    '@media (max-width: 599px)': {
      span: {
        fontSize: '18px',
      },
    },
    '@media (min-width: 600px) and (max-width: 959px)': {
      span: {
        fontSize: '30px',
      },
    },
    '@media (min-width: 960px)': {
      span: {
        fontSize: '40px',
      },
    },
  },
  CompanyTabText: {
    '.Company-Tabs  span': {
      backgroundColor: anchorColorCompany,
    },
    '.Company-Tabs button span': {
      color: anchorColorCompany,
      backgroundColor: 'transparent !important',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 'normal',
      fontWeight: 300,
      // fontFamily: styleFontFamily,
      textTransform: 'none',
      letterSpacing: '0.7px',
    },
    '@media (max-width: 599px)': {
      span: {
        fontSize: '10px',
      },
    },
    '@media (min-width: 600px) and (max-width: 959px)': {
      span: {
        fontSize: '15px',
      },
    },
    '@media (min-width: 960px)': {
      span: {
        fontSize: '20px',
      },
    },
  },
  CompanyAvatar: {
    backgroundColor: '#38c3a4',
  },
  anchorColor: anchorColorCompany,
  companyButton: {
    backgroundColor: '#2ea5f2',
    border: 'solid 2px #2ea5f2',
    fontSize: '16px',
    // fontFamily: styleFontFamily,
    padding: '0px 32px',
    color: '#fff',
    borderRadius: '200px',
    minWidth: '136px',
    height: '40px',
    cursor: 'pointer',
    outline: 'none',
    fontWeight: '400',
    position: 'relative',
    overflow: 'hidden',
    WebkitMaskImage: '-webkit-radial-gradient(white, black)',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: '0px',
      left: '0px',
      right: '0px',
      bottom: '0px',
      width: '100%',
      height: '100%',
      transform: 'translateX(-100%)',
      transition: 'transform 0.2s ease-in-out 0s',
      borderRadius: '200px',
      background: 'rgb(72, 191, 255)',
    },
    '&:hover:before, &:active:before, &:focus:before': {
      transform: 'translateX(0)',
      borderRadius: '200px',
      overflow: 'hidden',
      WebkitTransform: 'translate3d(0, 0, 0)',
    },
    '&:disabled': {
      border: 'solid 2px rgba(112,130,142,0.5)',
      color: '#70828e',
      backgroundColor: 'transparent',
      cursor: 'not-allowed',
    },
    '&:disabled:hover': {
      backgroundColor: 'transparent',
    },
    '&:hover:enabled': {
      border: 'solid 2px #48bfff',
    },
    '&:active': {
      backgroundColor: '#158cd9',
      border: 'solid 2px #158cd9',
    },
  },
};

export const materialStyles = {
  progress: {
    position: 'absolute',
    top: 'calc(50vh - (/* height */100px / 2))',
    left: 'calc(50vw - (/* width */140px / 2))',
    color: '#2ea5f3',
  },
  avatar: {
    width: 133,
    height: 133,
    border: 'solid #fff',
    marginTop: 57,
    marginBottom: 17,
  },
  profile_progress: {
    color: '#fff',
    marginTop: 57,
    marginBottom: 17,
  },
  tabsIndicator: {
    backgroundColor: '#2ea5f2',
  },
};
export const spinnerStyle = {
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  height: '100vh',
  width: '100vw',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: 'white',
  marginTop: 100,
  alignSelf: 'center',
};

export const boarderNoneStyle = {
  border: 'none',
  position: 'relative',
};
export const teamUpIncudedText = {
  color: '#4c6372',
  opacity: '0.5',
  fontFamily: 'Heebo',
  fontSize: '16px',
  fontWeight: 500,
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  textAlign: 'right',
  width: '175px',
  height: '24px',
};
