import 'babel-polyfill';
import 'unfetch/polyfill';
import React, { Fragment, Suspense } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import ReactGA from 'react-ga';
import { baseTheme, spinnerStyle } from './Theme/globalStyles';
import SecuredRoute from './components/SecuredRoute/SecuredRoute';
import NotAuthView from './components/NoAuthView';
import Auth from "./components/Auth/Auth"; //eslint-disable-line
import CallbackSilent from './components/Callback/callbacksilent';

import Zuora from './utils/Zuora';
import Footer from './components/Navigation/footer';
import Subpage from './components/Company/CCSubPage/Subpage';
import SubpageHeader from './components/Company/CCSubPage/SubpageHeader';
import SubpageBanner from './components/Company/CCSubPage/SubpageBanner';
import CountryFilter from './components/GeneralError/ErrorTypes/CountryFilter';
import {
  Account,
  RackspaceSettings,
  SubscriptionUpgrade,
  GeneralError,
} from '../src/components/index';

const auth = new Auth(process.env.REACT_APP_CLIENTID);
ReactGA.initialize('UA-11039107-1');
Zuora.initialize(
  window.location.hostname.match(new RegExp('account.company.com')) ? 'prod' : 'stage'
);

class TheRouter extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    return (
      <Router>
        <ThemeProvider theme={baseTheme}>
          <Suspense
            fallback={
              <div style={spinnerStyle}>
                <CircularProgress size={80} thickness={5} style={{ color: baseTheme.primary }} />
              </div>
            }
          >
            <Switch>
              {window.location.hostname.match(new RegExp('account.company.com')) ? (
                ''
              ) : (
                <Route
                  exact
                  path="/"
                  render={props => <NotAuthView auth={auth} ReactGA={ReactGA} {...props} />}
                />
              )}
              <Route
                path="/account"
                render={props => (
                  <SecuredRoute component={Account} auth={auth} ReactGA={ReactGA} {...props} />
                )}
              />
              <Route
                path="/emailpassword"
                render={props => (
                  <SecuredRoute
                    component={RackspaceSettings}
                    auth={auth}
                    ReactGA={ReactGA}
                    {...props}
                  />
                )}
              />
              <Route
                path="/subscriptions"
                render={props => (
                  <SecuredRoute component={Account} auth={auth} ReactGA={ReactGA} {...props} />
                )}
              />
              <Route
                path="/upgrade/:service"
                render={props => (
                  <SecuredRoute
                    component={SubscriptionUpgrade}
                    auth={auth}
                    ReactGA={ReactGA}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/callbacksilent"
                render={props => <CallbackSilent auth={auth} {...props} ReactGA={ReactGA} />}
              />
              <Fragment>
                <SubpageHeader theme={baseTheme} />
                <SubpageBanner />
                <Route
                  render={() => {
                    return (
                      <Fragment>
                        <Subpage>
                          <Switch>
                            <Route
                              path="/unauthorized"
                              render={props => {
                                return <GeneralError statusCode={401} history={props.history} />;
                              }}
                            />
                            <Route
                              path="/whereami"
                              render={props => {
                                return (
                                  <GeneralError
                                    statusCode={421}
                                    auth={auth}
                                    history={props.history}
                                  />
                                );
                              }}
                            />
                            <Route
                              path="/restricted"
                              render={props => {
                                return <CountryFilter history={props.history} />;
                              }}
                            />
                            <Route
                              render={props => (
                                <GeneralError statusCode={404} history={props.history} />
                              )}
                            />
                          </Switch>
                        </Subpage>
                        <Footer userProfile={{ source: 'company' }} />
                      </Fragment>
                    );
                  }}
                />
              </Fragment>
            </Switch>
          </Suspense>
        </ThemeProvider>
      </Router>
    );
  }
}

export default TheRouter;
// export default withStyles(materialStyles)(TheRouter);
