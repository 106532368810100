import styled, { css } from 'styled-components';

const Flex = styled.div`
  display: flex;
  flex-direction: ${props => (props.column ? 'column' : 'row')};
  flex-wrap: ${props => {
    if (props.wrapReverse) return 'wrap-reverse';
    else if (props.noWrap) return 'nowrap';
    return 'wrap';
  }};
  justify-content: ${props => {
    if (props.justifyCenter) return 'center';
    else if (props.justifyAround) return 'space-around';
    else if (props.justifyBetween) return 'space-between';
    else if (props.justifyEnd) return 'flex-end';
    return 'flex-start';
  }};
  align-items: ${props => {
    if (props.alignStretch) return 'stretch';
    else if (props.alignEnd) return 'flex-end';
    else if (props.alignCenter) return 'center';
    else if (props.alignBaseline) return 'baseline';
    return 'flex-start';
  }};
  align-content: ${props => {
    if (props.contentStart) return 'flex-start';
    else if (props.contentEnd) return 'flex-end';
    else if (props.contentCenter) return 'center';
    else if (props.contentBetween) return 'space-between';
    else if (props.contentAround) return 'contentAround';
    return 'stretch';
  }};

  ${props =>
    props.center &&
    css`
      justify-content: center;
      align-items: center;
    `};
`;

export default Flex;
