import React from 'react';
import CompanyUserFooterSection from '../Company/CompanyFooter/CompanyUserFooterSection';
import IMAGES from '../../images';

const footer = props => {
  const linkHostName = (() => {
    const link =
      typeof process.env.REACT_APP_LINKS_TARGET === 'string'
        ? JSON.parse(process.env.REACT_APP_LINKS_TARGET)
        : process.env.REACT_APP_LINKS_TARGET;
    switch (props.userProfile.source) {
      case 'officedepot':
      case 'od':
        return link.officedepot;
      default:
        return link.company;
    }
  })();

  return (
    <CompanyUserFooterSection>
      <div className="Company-Footer-Container">
        <div className="Company-Footer-Logo">
          <a
            href={(() => {
              const { host } = window.location; // eslint-disable-line
              const replaceString = new RegExp('account', 'gi');
              return `//${host.replace(replaceString, 'dashboard')}`;
            })()}
          >
            <img
              src={IMAGES.FOOTER_LOGO}
              srcSet={`${IMAGES.FOOTER_LOGO_2x} 2x, ${
                IMAGES.FOOTER_LOGO_3x
              } 3x`}
              alt="Company.com"
            />
          </a>
        </div>
        <div className="Company-Footer-Attribution">
          <span className="co-name">
            Company<span className="comColor">.com</span>
          </span>&#169; {new Date().getFullYear()} All Rights Reserved
        </div>
        <div className="terms-use">
          <a
            href={(() => {
              return `${linkHostName}/contact-us`;
            })()}
          >
            Contact Us
          </a>
        </div>
        <div className="terms-use">
          <a
            href={(() => {
              return `${linkHostName}/terms-of-use`;
            })()}
          >
            Terms of Use
          </a>
        </div>
        <div className="terms-use">
          <a
            href={(() => {
              return `${linkHostName}/privacy-policy`;
            })()}
          >
            Privacy Policy
          </a>
        </div>
      </div>
    </CompanyUserFooterSection>
  );
};

export default footer;
