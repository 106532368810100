export default function reducer(
  state = {
    fetching: false,
    fetched: false,
    updating: false,
    updated: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    //  update of child user
    case 'UPDATE_MARKETO':
      return { ...state, fetching: true };

    case 'UPDATE_MARKETO_REJECTED':
      return { ...state, fetching: false, error: action.payload };

    case 'UPDATE_MARKETO_FUFILLED':
      return {
        ...state,
        fetching: false,
        fetched: false,
      };
    default:
      return { ...state };
  }
}
