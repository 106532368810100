import styled from 'styled-components';
import Type from '../ErrorBoundary/Shared/Type';

const StyledGeneralError = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 80px 144px 64px;
  text-align: center;
  a {
    text-decoration: none;
  }
  .error__icon {
    margin: 4px;
  }
  .error__icon__shadow {
    width: 272px;
    margin: auto;
    border-bottom: 1px solid #e8f0f5;
  }
  .error__text {
    font-size: 20px;
    margin: 4px 4px 20px 4px;
  }
  .error__btn__container {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .error__btn {
    background-color: #09afbb;
    box-shadow: inset 0px 0px 0px 2px #09afbb;
    border: 0px !important;
    padding: 0px 32px;
    color: #fff;
    border-radius: 8px;
    min-width: 100px !important;
    height: 40px;
    cursor: pointer;
    font-size: 15px;
    font-weight: 600;
  }
  @media (max-width: 599px) {
    margin-left: auto;
    margin-right: auto;
  }
`;

const Title = styled(Type)`
  margin: 32px;
`;

StyledGeneralError.Title = Title;

export default StyledGeneralError;
