import React from 'react';
import GeneralError from '../GeneralError/generalError';
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    this.setState({ hasError: true });
    console.log(`The Error:${error} Where it is: ${info.componentStack}`);
  }

  render() {
    if (this.state.hasError) {
      return <GeneralError />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
