export default function reducer(
  state = {
    fetching: false,
    fetched: false,
    updating: false,
    updated: false,
    error: null,
    productStatus: { email: false, teamUp: true },
    isEmailOn: undefined,
    isAddMailBox:false
  },
  action
) {
  switch (action.type) {
    //  update of child user
    case 'UPDATE_CHILD_USER':
      return {
        ...state,
        updating: true,
        updated: false
      };

    case 'UPDATE_CHILD_USER_FUFILLED': {
      const { userProfile } = action.payload; // eslint-disable-line no-unused-vars
      return {
        ...state,
        updating: false,
        updated: true,
        childUser: action.payload
      };
    }

    case 'UPDATE_CHILD_USER_REJECTED': {
      return {
        ...state,
        updating: false,
        updated: false,
        error: action.payload
      };
    }

    case 'CREATE_CHILD_USER_EMAIL':
      return {
        ...state,
        fetching: true
      };

    case 'CREATE_CHILD_USER_EMAIL_REJECTED':
      return {
        ...state,
        fetching: false,
        error: action.payload
      };

    case 'CREATE_CHILD_USER_EMAIL_FUFILLED':
      return {
        ...state,
        fetching: false,
        updated: true,
        formDetails: action.payload
      };
    case 'RESEND_CHILD_USER_ACTIVATION_EMAIL':
    case 'DELETE_CHILD_USER_EMAIL':
      return {
        ...state,
        fetching: true
      };
    case 'RESEND_CHILD_USER_ACTIVATION_EMAIL_REJECTED':
    case 'DELETE_CHILD_USER_EMAIL_REJECTED':
      return {
        ...state,
        fetching: false,
        error: action.payload
      };
    case 'RESEND_CHILD_USER_ACTIVATION_EMAIL_FUFILLED':
    case 'DELETE_CHILD_USER_EMAIL_FUFILLED':
      return {
        ...state,
        fetching: false,
        updated: true
      };
    case 'CHILD_USER_STATUS_CHANGE':
      return {
        ...state,
        updating: true
      };
    case 'CHILD_USER_STATUS_CHANGE_FUFILLED':
      return {
        ...state,
        updating: false
      };
    case 'CHILD_USER_STATUS_CHANGE_REJECTED':
      return {
        ...state,
        updating: false,
        error: action.payload
      };
    case 'CHILD_PRODUCT_STATUS':
      const productState = Object.assign({}, state.productStatus);
      productState[action.payload.key] = action.payload.value;
      return {
        ...state,
        productStatus: productState
      };
    case 'IS_EMAIL_CHECKED':
      return {
        ...state,
        isEmailOn: action.payload
      };
      case 'IS_MAILBOX_ADDED':
      return {
        ...state,
        isAddMailBox: action.payload
      };
    default:
      return { ...state };
  }
}
