import styled from 'styled-components';
import Type from '../ErrorBoundary/Shared/Type';

const StyledCountryFilter = styled.div`
  font-family: Heebo, Helvetica, Times New Roman, sans-serif;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: auto;
    width: 708px;
    height: 580px;

    border-radius: 4px;
    box-shadow: 0 3px 8px 0 rgba(106, 126, 150, 0.14);
    background-color: #ffffff;
  }

  .filter-logo {
    margin: 60px 0 30px 0;
  }
  .filter-subheader {
    display: flex;
    flex-direction: column;
    font-size: 20px;
    font-weight: 300;
    width: 64%;
    line-height: normal;
  }

  @media (max-width: 906px) {
    width: 80%;
  }
`;

const Title = styled(Type)`
  width: 70%;
`;

StyledCountryFilter.Title = Title;

export default StyledCountryFilter;
