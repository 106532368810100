import { applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';

import reducer from './reducers';

const storeProd = createStore(reducer, composeWithDevTools(applyMiddleware(promise(), thunk)));
const storeLocal = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(createLogger(), promise(), thunk))
);

export default window.location.hostname.match( new RegExp('account.company.com')) ? storeProd : storeLocal; // eslint-disable-line
