import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { spinnerStyle } from '../../Theme/globalStyles';

class SilentCallback extends Component {
  componentDidMount() {
    this.handleAuthentication();
  }

  handleAuthentication() {
    const nextState = this.props;
    let nextPage = '';
    if (nextState.location.state) {
      nextPage = nextState.location.state.from.pathname;
      if (nextState.location.state.from.search) {
        nextPage = `${nextPage}${nextState.location.state.from.search}`;
      }
    }

    nextState.auth.checkSession(nextPage).then(
      resolve => {
        nextState.auth.setSession(resolve);
        nextState.auth.isAuthCheckSession = true;
        nextState.history.replace(nextPage);
      },
      reject => {
        console.log(reject);
        nextState.auth.logout();
        // nextState.history.replace('/');
      }
    );
  }

  render() {
    // const style = {
    //   position: 'absolute',
    //   display: 'flex',
    //   justifyContent: 'center',
    //   height: '100vh',
    //   width: '100vw',
    //   top: 0,
    //   bottom: 0,
    //   left: 0,
    //   right: 0,
    //   backgroundColor: 'white',
    //   marginTop: 100,
    //   alignSelf: 'center',
    // };

    return (
      <div style={spinnerStyle}>
        <CircularProgress size={80} thickness={5} />
      </div>
    );
  }
}

SilentCallback.protpTypes = {
  auth: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default SilentCallback;
