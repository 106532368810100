import styled from 'styled-components';

const StyledSubpageBanner = styled.div`
  background-image: url(${props => props.theme.banner__image});
  display: flex;
  width: 100%;
  min-height: 164px;
  background-size: cover;
  align-items: center;
`;

export default StyledSubpageBanner;
