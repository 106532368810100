export default function reducer(state = {
  weeblyDetails: {},
  fetching: false,
  fetched: false,
  updating: false,
  updated: false,
  validating: false,
  validated: false,
  error: null,
}, action) {
  switch (action.type) {
    case 'UPDATE_SUBSCRIPTION_WEEBLY':
      return {
        ...state,
        updating: true,
      };
    case 'UPDATE_SUBSCRIPTION_WEEBLY_FUFILLED':
      return {
        ...state,
        updating: false,
        updated: true,
      };
    case 'UPDATE_SUBSCRIPTION_WEEBLY_REJECTED': {
      return {
        ...state,
        updating: false,
        error: action.payload,
      };
    }
    case 'VALIDATION_REQUEST_WEEBLY':
      return {
        ...state,
        validating: true,
      };
    case 'VALIDATION_REQUEST_WEEBLY_SUCCESS':
      return {
        ...state,
        validating: false,
        validated: true,
        error: false,
      };
    case 'VALIDATION_REQUEST_WEEBLY_FAIL':
      return {
        ...state,
        validating: false,
        validated: true,
        error: true,
      };
    default:
      return { ...state };
  }
}
