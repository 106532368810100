import auth0 from 'auth0-js';

class Auth {
  constructor() {
    let domain = process.env.REACT_APP_DOMAIN;
    let clientID = process.env.REACT_APP_CLIENT_ID;
    let audience = process.env.REACT_APP_AUDIENCE;
    let localSource = 'company';
    if (localStorage.getItem('user_info')) {
      const { 'https://company.com/user_authorization': authorization } = JSON.parse(localStorage.getItem('user_info'));
      localSource = authorization.groups[0].split(':')[1].toLowerCase();
    }else {
      localSource = window.location.host.split('.')[0];
    }

    if (localSource == 'paysafe2' || localSource == 'priority2' || localSource == 'merchantprotectionplus' || localSource == 'metabaseq') {
      domain = process.env.REACT_APP_PAYSAFE2_DOMAIN;
      clientID = process.env.REACT_APP_PAYSAFE2_CLIENT_ID;
      audience = process.env.REACT_APP_PAYSAFE2_AUDIENCE;
    }
    this.auth0 = new auth0.WebAuth({
      domain,
      clientID,
      redirectUri: `${window.location.origin}/account`,
      audience,
      responseType: 'token id_token',
      scope: 'openid profile email',
    });

    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuth = this.handleAuth.bind(this);
    this.setSession = this.setSession.bind(this);
    this.pollAuthenticated = this.pollAuthenticated.bind(this);
    this.authPoller = null;
    this.isAuthCheckSession = false;
    this.isUserInfo = false;
  }

  _getIdToken() {
    return localStorage.getItem('id_token');
  }

  _getAccessToken() {
    return localStorage.getItem('access_token'); // eslint-disable-line no-undef
  }

  _getUserInfo() {
    return localStorage.getItem('user_info'); // eslint-disable-line no-undef
  }

  setSession(authResult) {
    const expiresAt = JSON.stringify(authResult.expiresIn * 1000 + new Date().getTime());
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);
    return true;
  }

  handleAuth() {
    const promise = new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          if (this.setSession(authResult) === true) {
            resolve(true);
          }
        } else if (err) {
          reject(err);
        }
      });
    });
    return promise;
  }

  pollAuthenticated(fromRoute, callback) {
    this.authPoller = setInterval(() => {
      this.checkSession(fromRoute).then(
        result => {
          this.setSession(result);
          callback(true);
        },
        () => {
          this.logout();
          clearInterval(this.authPoller);
          callback(false);
        }
      );
    }, 86400000);
  }

  checkSession(fromRoute) {
    return new Promise((resolve, reject) => {
      this.auth0.checkSession(
        {
          postMessageDataType: 'auth0:silent-authentication',
          state: fromRoute,
        },
        (err, result) => {
          if (err) {
            reject(err);
          } else {
            resolve(result);
          }
        }
      );
    });
  }

  logout() {
    localStorage.removeItem('user_info');
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    const dashboardUrl = window.location.host.replace(/account/g, 'dashboard');

    this.auth0.logout({
      returnTo: `${window.location.protocol}//${dashboardUrl}/logout`,
      federated: true,
    });

    return true;
  }
  async getUserSource() {
    const { 'https://company.com/user_authorization': authorization } = await this.getUserInfo();
    return authorization.groups[0].split(':')[1].toLowerCase();
  }

  async isAuthenticated(pathName) {
    return new Promise((resolve, reject) => {
      if (!this.isAuthCheckSession) {
        this.auth0.checkSession(
          {
            postMessageDataType: 'auth0:silent-authentication',
            state: pathName,
          },
          (err, result) => {
            if (err) {
              reject(false);
            } else {
              this.setSession(result);
              this.isAuthCheckSession = true;
              resolve(true);
            }
          }
        );
      }
      if (localStorage.getItem('expires_at') && this.isAuthCheckSession) {
        if (new Date().getTime() < JSON.parse(localStorage.getItem('expires_at'))) {
          resolve(true);
        } else {
          reject(false);
        }
      }
    });
  }

  getUserInfo() {
    const promise = new Promise((resolve, reject) => {
      const accessToken = this._getAccessToken();
      const userinfo = null;
      if (userinfo && this.isUserInfo) {
        this._getUserInfo();
        resolve(JSON.parse(userinfo));
      } else {
        this.checkSession(window.location.origin).then(
          () => {
            if (accessToken) {
              // Call userinfo endpoint
              this.auth0.client.userInfo(accessToken, (err, profile) => {
                if (err) {
                  reject(err);
                }
                if (profile) {
                  localStorage.setItem('user_info', JSON.stringify(profile));
                  this.isUserInfo = true;
                  resolve(profile);
                }
              });
            }
          },
          () => {
            this.logout();
          }
        );
      }
    });
    return promise;
  }

  login() {
    this.auth0.authorize();
  }
}

export default Auth;
