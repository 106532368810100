import React from 'react';

const NotAuthView = props => {
  const userLogin = () => {
    props.auth.login();
  };
  return (
    <div>
      <h1>Not Authenticated</h1>
      Click here to{' '}
      <button
        onClick={userLogin}
        style={{
          color: 'blue',
          cursor: 'pointer',
          textDecoration: 'none',
          backgroundColor: 'transparent',
          fontSize: '16px',
          border: 'none',
          outline: 'none',
          display: 'inline',
          margin: '0',
          padding: '0'
        }}
      >
        Login
      </button>.
    </div>
  );
};

export default NotAuthView;
