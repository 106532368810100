export default function reducer(state = {
  formDetails: {},
  fetching: false,
  fetched: false,
  updating: false,
  updated: false,
  error: null,
}, action) {
  switch (action.type) {
    case 'FETCH_PAYMENT_FORM_DETAILS':
      return {...state, fetching: true };

    case 'FETCH_PAYMENT_FORM_DETAILS_REJECTED':
      return { ...state, fetching: false, error: action.payload };

    case 'FETCH_PAYMENT_FORM_DETAILS_FUFILLED':
      return {
        ...state,
        fetching: false,
        fetched: true,
        formDetails: action.payload,
      };
    default:
      return { ...state };
  }
}